import ProductReviewForm from "../component/ProductReviewForm";
import {REVIEW_POPUP_ID} from "@scandipwa/scandipwa/src/component/ProductReviews/ProductReviews.config";
import Popup from 'Component/Popup/Popup.container';

const renderReviewPopup = (args, callback, instance) => {

    const { dataSource, dataSource: {name} } = instance.props;

    return (
        <Popup
            id={ REVIEW_POPUP_ID }
            productName={name}
            mix={ { block: 'ProductReviews', elem: 'Popup' } }
        >
            <ProductReviewForm product={ dataSource } />
        </Popup>
    );
};

export default {
    'Route/ProductPage/Component': {
        'member-function': {
            renderReviewPopup: renderReviewPopup,
        }
    },
};
