/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import React, {ComponentType, PureComponent,} from 'react';
import {RouteComponentProps, withRouter} from 'react-router';

import {SwiperJSGalleryComponentProps, SwiperJSGalleryComponentState,} from './SwiperJSGallery.type';

import './SwiperJSGallery.style';
import {Swiper, SwiperSlide} from "swiper/react";
import {Controller, EffectCreative, EffectFade, FreeMode, Navigation, Pagination, Thumbs} from "swiper";
import CloseIcon from "Component/CloseIcon"
import getStore from "Util/Store";

export class SwiperJSGalleryComponent extends PureComponent<
    SwiperJSGalleryComponentProps,
    SwiperJSGalleryComponentState
> {
    static defaultProps: Partial<SwiperJSGalleryComponentProps> = {
        gallery: "",
    };

    __construct(props: SwiperJSGalleryComponentProps): void {
        super.__construct?.(props);
        this.closeGallery = this.closeGallery.bind(this);
        this.state = {
            galleryThumbsSwiper: undefined, gallerySwiper: undefined,
        };
        this.updateThumbSize = this.updateThumbSize.bind(this);
    }

    componentWillMount() {
        this.setState({
            galleryThumbsSwiper: undefined,
            gallerySwiper: undefined,
        })
        // this.state = {
        //     galleryThumbsSwiper: undefined, gallerySwiper: undefined,
        // };
    }

    componentDidMount(): void {
        window.addEventListener('resize', this.updateThumbSize);
    }

    componentWillUnmount(): void {
        window.removeEventListener('resize', this.updateThumbSize);
    }

    // handleResize() {
    //     // console.clear();
    //     console.log(Math.random());
    //     this.updateThumbSize();
    // }

    closeGallery(): void {
        document.body.classList.remove('fullscreen');
    }

    updateThumbSize() {
        const state = getStore().getState();
        const {
            device: {isMobile}
        } = state.ConfigReducer;
        let picture = document.querySelectorAll('.ProductGalleryWrapper-Picture .swiper-wrapper');
        if (typeof picture[0] !== 'undefined') {
            let thumbs = document.querySelectorAll('.ProductGalleryWrapper-Thumbs .swiper-wrapper');
            let height;
            if (picture[0].clientHeight < 120) {
                return;
            }
            if (isMobile) {
                height = 'auto';
            } else {
                height = picture[0].clientHeight + 'px';
            }

            //@ts-ignore
            thumbs[0].style.height = height;
        }
    }

    renderGalleryImages(productImages: any, source: string) {
        return productImages.map(function (item: any, i: React.Key | null | undefined) {

            const {
                base: {url: baseImage = false} = false,
                large: {url: largeImage = false} = false,
                thumbnail: {url: thumbnailImage = false} = false
            } = item;

            if (baseImage && !largeImage) {
                return <SwiperSlide key={i}><img src={baseImage}/></SwiperSlide>
            }
            if (largeImage) {
                return <SwiperSlide key={i}><img src={largeImage}/></SwiperSlide>
            }
            return null;
        });
    }

    render() {
        const {galleryThumbsSwiper} = this.state;
        const {gallery} = this.props;

        // console.clear();
        // console.log(this.props);
        let galleryClass = 'gallerySwiper';

        if (gallery.length === 1) {
            galleryClass = galleryClass + ' oneImage';

        }

        const state = getStore().getState();
        const {
            device: {isMobile}
        } = state.ConfigReducer;

        const thumbsSwiper = <Swiper
            onSwiper={(swiper) => {
                this.setState({galleryThumbsSwiper: swiper});
            }}
            // onResize={(swiper) => {
            //
            //     // console.clear();
            //     console.log(swiper);
            //     console.log(this.props);
            //
            //     console.log(isMobile);
            //     let picture = document.querySelectorAll('.ProductGalleryWrapper-Picture .swiper-wrapper');
            //     console.log(picture[0]);
            //     if (typeof picture[0] !== 'undefined') {
            //         console.log(picture[0].clientHeight);
            //         let thumbs = document.querySelectorAll('.ProductGalleryWrapper-Thumbs .swiper-wrapper');
            //         console.log(thumbs[0]);
            //         let height;
            //         if (isMobile) {
            //             height = 'auto';
            //         }else {
            //             height = picture[0].clientHeight + 'px';
            //         }
            //         console.log(height);
            //         //@ts-ignore
            //         thumbs[0].style.height = height;
            //     }
            // }}

            // spaceBetween={(isMobile ? 8 : 32)}
            // spaceBetween={8}
            direction={(isMobile ? 'horizontal' : 'vertical')}
            // navigation={false}
            slidesPerView={4}
            // centeredSlides={(isMobile && gallery.length < 4)}
            // autoHeight={true}
            // height={427}
            freeMode={true}
            // navigation={{
            //     prevEl: "#swiper-button-prev-thumb-popup-image",
            //     nextEl: "#swiper-button-next-thumb-popup-image",
            // }}

            modules={[FreeMode, Navigation, Thumbs]}
            className={(isMobile && gallery.length < 4) ? "center" : ''}
        >
            {this.renderGalleryImages(gallery, 'gallery')}

        </Swiper>;

        let mainSwiper = <Swiper
            effect={"fade"}
            fadeEffect={{
                crossFade: true
            }}
            speed={1000}
            // navigation={true}
            onSwiper={(swiper) => {
                this.setState({gallerySwiper: swiper})
            }}

            onResize={(swiper) => {

                this.updateThumbSize();
                // const thumbSwiper = this.state.galleryThumbsSwiper && !this.state.galleryThumbsSwiper.destroyed ? this.state.galleryThumbsSwiper : null;
                // // console.clear();
                // console.log(swiper);
                // console.log(swiper.height);
                // console.log(thumbSwiper.el);
                // console.log(this.props);
                // let picture = document.querySelectorAll('.ProductGalleryWrapper-Picture .swiper-wrapper');
                // if (typeof picture[0] !== 'undefined') {
                //     let thumbs = document.querySelectorAll('.ProductGalleryWrapper-Thumbs .swiper-wrapper');
                //     let height;
                //     if (isMobile) {
                //         height = 'auto';
                //     } else {
                //         height = picture[0].clientHeight + 'px';
                //     }
                //     //@ts-ignore
                //     thumbs[0].style.height = height;
                // }
            }}
            // pagination={{
            //     clickable: true,
            // }}
            // onClick={(swiper) => {
            //     // let {realIndex} = swiper;
            //     // realIndex++;
            //     if (!isMobile) {
            //         document.body.classList.add('fullscreen');
            //     }
            //
            //
            // }}
            onSlideChange={(swiper) => {
                // let {realIndex} = swiper;
                // realIndex++;
                let thumbs = document.querySelectorAll('.ProductGalleryWrapper-Thumbs .swiper-slide');
                thumbs.forEach((element) => {
                    element.classList.remove('swiper-slide-active');
                })

            }}
            modules={[EffectCreative, EffectFade, FreeMode, Navigation, Thumbs, Controller, Pagination]}
            // className={galleryClass}
            thumbs={{swiper: this.state.galleryThumbsSwiper && !this.state.galleryThumbsSwiper.destroyed ? this.state.galleryThumbsSwiper : null}}

        >
            {this.renderGalleryImages(gallery, 'gallery')}
        </Swiper>;

        setTimeout(function () {
            window.dispatchEvent(new Event("resize"));
            // console.log("resize event");
        }, 1);


        return (
            <>
                <div block="ProductGalleryWrapper" elem="Picture">
                    {mainSwiper}
                    <button
                        className="closeGallery"
                        onClick={this.closeGallery}
                    >
                        <CloseIcon/>
                    </button>
                </div>
                <div block="ProductGalleryWrapper" elem="Thumbs">{thumbsSwiper}</div>
            </>
        )
    }

}

export default withRouter(
    SwiperJSGalleryComponent as unknown as ComponentType<
        RouteComponentProps & SwiperJSGalleryComponentProps
    >,
);
