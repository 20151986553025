/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

export enum ProductPageTabs {
    INFORMATION = 'information',
    ATTRIBUTES = 'attributes',
    REVIEWS = 'reviews',
    // PRODDUCTCARE = 'product_care',
    // SHIPPINGDETAILS = 'shipping_details',
    // PRODUCTDISCLAIMER = 'product_disclaimer',
    PRODUCTSHELFLIFESTORAGE = 'product_shelf_life_storage',
    PRODUCTINGREDIENTS = 'product_ingredients',
}
