import { connect } from "react-redux";

import {
    mapStateToProps as sourceMapStateToProps,
    mapDispatchToProps as sourceMapDispatchToProps,
    ProductPageContainer as SourceProductPageContainer,
} from "SourceRoute/ProductPage/ProductPage.container";

import { RootState } from "Util/Store/Store.type";

import { scrollToTop } from "Util/Browser";
import { ReactElement } from "Type/Common.type";
import ProductPage from "./ProductPage.component";

export const mapStateToProps = (state: RootState) => ({
    ...sourceMapStateToProps(state),
    // TODO extend mapStateToProps
});

import { Dispatch } from "redux";
export const mapDispatchToProps = (dispatch: Dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
});

export class ProductPageContainer extends SourceProductPageContainer {
    componentDidMount(): void {
        /**
         * Always request product information. In this case we will have updated data.
         * Service worker will return previous information and updated new information
         * through broadcast.
         */
        this.requestProduct();

        /**
         * Always make sure the navigation switches into the MENU tab
         * */
        this.updateNavigationState();

        /**
         * Ensure transition PDP => homepage => PDP always having proper meta
         */
        this.updateMeta();

        /**
         * Make sure to update header state, the data-source will
         * define the correct information to use for update
         * (it can be a product, history state product or an empty object).
         */
        this.updateHeaderState();
        this.updateBreadcrumbs();

        /**
         * Scroll page top in order to display it from the start
         */
        scrollToTop();

        document.body.classList.remove("category-page");
        document.body.classList.add("product-page");
        //@ts-ignore
        window.currentRoute = "product";
        // console.clear();
        // console.log(this.state);
        // console.log(this.props);
    }

    componentWillUnmount(): void {
        window.isPrefetchValueUsed = false;
        document.body.classList.remove("product-page");
        //@ts-ignore
        window.currentRoute = "";
    }

    render(): ReactElement {
        // console.clear();
        // console.log(this.state);
        // console.log(this.props);
        return (
            <ProductPage
                {...this.containerFunctions}
                {...this.containerProps()}
            />
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductPageContainer);
