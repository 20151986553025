import {
    Popup as SourcePopup,
    ProductActions as SourceProductActions,
    ProductAttributes as SourceProductAttributes,
    ProductInformation as SourceProductInformation,
    ProductLinks as SourceProductLinks,
    ProductPageComponent as SourceProductPageComponent,
    ProductReviewForm as SourceProductReviewForm,
    ProductReviews as SourceProductReviews,
    ProductTabs as SourceProductTabs,
} from 'SourceRoute/ProductPage/ProductPage.component';
import {Suspense} from 'react';

import ContentWrapper from 'Component/ContentWrapper';
import Loader from 'Component/Loader/Loader.component';
import ProductGallery from 'Component/ProductGallery';
import NoMatchHandler from 'Route/NoMatchHandler';
import {ProductPageTabs} from './ProductPage.config';
import {LinkedProductType} from 'Store/LinkedProducts/LinkedProducts.type';
import {ReactElement} from 'Type/Common.type';
import ExpandableContent from 'Component/ExpandableContent';
import Html from "Component/Html";
import {ProductPageTab} from './ProductPage.type';
import SwiperJSGallery from "./../../component/SwiperJSGallery";

import './ProductPage.override.style';
import TextPlaceholder from 'Component/TextPlaceholder';
import { TextPlaceHolderLength } from 'Component/TextPlaceholder/TextPlaceholder.config';
import {CartBundleOption} from "Query/Cart.type";
import {GQLCurrencyEnum, GQLSelectedBundleOptionValue} from "Type/Graphql.type";
import {formatPrice} from "Util/Price";

//TODO: implement ProductReviews
export const ProductReviews = SourceProductReviews;

//TODO: implement ProductTabs
export const ProductTabs = SourceProductTabs;

//TODO: implement ProductAttributes
export const ProductAttributes = SourceProductAttributes;

//TODO: implement ProductReviewForm
export const ProductReviewForm = SourceProductReviewForm;

//TODO: implement ProductLinks
export const ProductLinks = SourceProductLinks;

//TODO: implement ProductInformation
export const ProductInformation = SourceProductInformation;

//TODO: implement Popup
export const Popup = SourcePopup;

//TODO: implement ProductActions
export const ProductActions = SourceProductActions;

export class ProductPageComponent extends SourceProductPageComponent {

    componentDidMount(): void {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount(): void {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = this._handleResize.bind(this);

    _handleResize() {
        //@ts-ignore
        if (typeof window.productPageToggleExpand !== "undefined") {
            //@ts-ignore
            window.productPageToggleExpand();
        }
    }

    renderProductInformationTab(key: string): ReactElement {
        let {
            dataSource: { type_id, description: { html } = {} },
            areDetailsLoaded,
        } = this.props;


        if (!html) {
            html = '<p>N/A</p>';
        }

        return (
            <Suspense fallback={ null }>
                <ProductInformation
                    htmlDescription={ html }
                    areDetailsLoaded={ areDetailsLoaded }
                    key={ key }
                    type_id={ type_id }
                />
            </Suspense>
        );
    }

    tabMap: Record<ProductPageTabs, ProductPageTab> = {
        [ProductPageTabs.INFORMATION]: {
            name: __('Product Description'),
            shouldTabRender: (): boolean => {
                const {isInformationTabEmpty} = this.props;

                //hide product description tab from the frontend
                return true;
                return !isInformationTabEmpty;
            },
            render: (key: string): ReactElement => this.renderProductInformationTab(key),
        },
        [ProductPageTabs.ATTRIBUTES]: {
            name: __('Details'),
            shouldTabRender: (): boolean => {
                //@ts-ignore
                // const {isAttributesTabEmpty, activeProduct: {product_weight}} = this.props;
                const {isAttributesTabEmpty} = this.props;

                return true;

                // if (product_weight) {
                //     return true;
                // }
                // if (isAttributesTabEmpty) {
                //     return false;
                // }
                // return !isAttributesTabEmpty;
            },
            render: (key: string): ReactElement => this.renderProductAttributesTab(key),
        },
        // [ProductPageTabs.PRODDUCTCARE]: {
        //     name: __('Product Care'),
        //     shouldTabRender: () => true,
        //     render: (key: string): ReactElement => this.renderProductCareTab(key)
        //
        // },
        // [ProductPageTabs.SHIPPINGDETAILS]: {
        //     name: __('Shipping Details'),
        //     shouldTabRender: () => true,
        //     render: (key: string): ReactElement => this.renderShippingDetailsTab(key)
        //
        // },
        // [ProductPageTabs.PRODUCTDISCLAIMER]: {
        //     name: __('Important Notes'),
        //     shouldTabRender: () => true,
        //     render: (key: string): ReactElement => this.renderProductDisclaimerTab(key)
        //
        // },
        [ProductPageTabs.PRODUCTSHELFLIFESTORAGE]: {
            name: __('Shelf Life & Storage'),
            shouldTabRender: () => true,
            render: (key: string): ReactElement => this.renderProductShelfLifeStorageTab(key)

        },
        [ProductPageTabs.PRODUCTINGREDIENTS]: {
            name: __('Ingredients'),
            shouldTabRender: () => true,
            render: (key: string): ReactElement => this.renderProductIngredientsTab(key)

        },
        [ProductPageTabs.REVIEWS]: {
            name: __('Reviews'),
            // Return true since we always show 'Add review' button
            shouldTabRender: (): boolean => {
                const {areReviewsEnabled} = this.props;

                return areReviewsEnabled;
            },
            render: (key: string): ReactElement => this.renderProductReviewsTab(key),
        },
    };

    renderProductBundleOptions(itemOptions: CartBundleOption[] = []): ReactElement {
        if (!itemOptions.length) {
            return null;
        }

        return (
            <div
                block="Ingredients"
                elem="Items"
            >
                <p>See individual product ingredients:</p>
                { itemOptions.map(this.renderProductBundleOption.bind(this)) }
            </div>
        );
    }

    renderProductBundleOption(option: CartBundleOption): ReactElement {
        //@ts-ignore
        const { option_id, selection_details } = option;

        return selection_details.map(this.renderBundleProductOptionLabel.bind(this));
        // return (
        //     <div
        //         block="Ingredients"
        //         elem="Item"
        //         key={ option_id }
        //     >
        //         { selection_details.map(this.renderBundleProductOptionLabel.bind(this)) }
        //     </div>
        // );
    }

    renderBundleProductOptionLabel(option: CartBundleOption): ReactElement {
        //@ts-ignore
        const { product_name, product_link} = option;

        // if (values.length === 0) {
        //     return null;
        // }

        return (
            <a href={product_link}>{product_name}</a>
        );
    }

    //@ts-ignore
    renderProductShelfLifeStorageTab(key: string): ReactElement {
        const {
            activeProduct: {
                attributes
            }
        } = this.props;

        // @ts-ignore
        const found = Object.values(attributes).find((element) => element.attribute_code === 'shelf_life_storage');

        if (!(found)) {
            return null;
        }

        return (
            <Suspense fallback={<Loader/>} key={key}>
                <ExpandableContent
                    heading='Shelf Life & Storage'
                    mix={{block: 'ProductShelfLifeStorage', elem: 'Content'}}
                >
                    <Html
                        // @ts-ignore
                        content={(found.attribute_value) ? found.attribute_value : '<p>N/A</p>'}/>
                </ExpandableContent>
            </Suspense>
        );
    }

    //@ts-ignore
    renderProductIngredientsTab(key: string): ReactElement {
        const {
            activeProduct: {
                attributes,
                bundle_options = {}
            }
        } = this.props;

        // @ts-ignore
        const found = Object.values(attributes).find((element) => element.attribute_code === 'ingredients');

        if (!(found)) {
            return null;
        }

        //@ts-ignore
        const bundleOptions = this.renderProductBundleOptions(bundle_options);

        return (
            <Suspense fallback={<Loader/>} key={key}>
                <ExpandableContent
                    heading='Ingredients'
                    mix={{block: 'ProductIngredients', elem: 'Content'}}
                >

                    {(bundleOptions) ? bundleOptions :
                    <Html
                        // @ts-ignore
                        content={(found.attribute_value) ? found.attribute_value : '<p>N/A</p>'}/>
                    }
                </ExpandableContent>
            </Suspense>
        );
    }

    //@ts-ignore
    renderProductCareTab(key: string): ReactElement {
        const {
            activeProduct: {
                //@ts-ignore
                product_instruction
            }
        } = this.props;

        return (
            <Suspense fallback={<Loader/>} key={key}>
                <ExpandableContent
                    heading='Product Care'
                    mix={{block: 'ProductCare', elem: 'Content'}}
                >
                    <Html content={product_instruction}/>
                </ExpandableContent>
            </Suspense>
        );
    }

    //@ts-ignore
    renderProductDisclaimerTab(key: string): ReactElement {
        const {
            activeProduct: {
                //@ts-ignore
                product_disclaimer
            }
        } = this.props;

        return (
            <Suspense fallback={<Loader/>} key={key}>
                <ExpandableContent
                    heading='Important Notes'
                    mix={{block: 'ProductDisclaimer', elem: 'Content'}}
                >
                    <Html content={product_disclaimer}/>
                </ExpandableContent>
            </Suspense>
        );
    }

    //@ts-ignore
    renderShippingDetailsTab(key: string): ReactElement {
        // console.clear();
        // console.log(key);
        // console.log(this.props);
        const {
            activeProduct: {
                //@ts-ignore
                product_disclaimer,
                //@ts-ignore
                manufacture_delivery_date,
                //@ts-ignore
                manufacture_time,
                salable_qty
            }
        } = this.props;

        return (
            <Suspense fallback={<Loader/>} key={key}>
                <ExpandableContent
                    heading='Shipping Details'
                    mix={{block: 'ShippingDetails', elem: 'Content'}}
                >
                    <p>{(manufacture_delivery_date) ?
                        'Product will be shipped by ' + manufacture_delivery_date : manufacture_time}</p>
                    {/*<p>{(manufacture_delivery_date) ?*/}
                    {/*    'Manufacture time ' + manufacture_time : '' }</p>*/}
                    {/* <CmsBlock identifier='shipping-details-tab' /> */}
                    {/*<Html content={product_disclaimer} />*/}
                </ExpandableContent>
            </Suspense>
        );
    }

    renderProductName(): ReactElement {
        return null;
    }

    renderAdditionalSections(): ReactElement {
        const {
            areDetailsLoaded,
        } = this.props;

        return (
            <>
                {/* { this.renderProductTabs() } */}
                <Suspense fallback={null}>
                    <ProductLinks
                        linkType={LinkedProductType.RELATED}
                        title={__('Recommended for you')}
                        areDetailsLoaded={areDetailsLoaded}
                    />
                </Suspense>
                <Suspense fallback={null}>
                    <ProductLinks
                        linkType={LinkedProductType.UPSELL}
                        title={__('You might also like')}
                        areDetailsLoaded={areDetailsLoaded}
                    />
                </Suspense>
            </>
        );
    }


    renderProductActionsPlaceholder() {
        const {activeProduct: {attributes = {}, configurable_options = {}}} = this.props;
        let optionsPlaceholder = '<div class="TextPlaceholder-Options">';
        //@ts-ignore
        Object.keys(configurable_options).reduce((accumulator, value) => {
            optionsPlaceholder += '<div class="TextPlaceholder-OptionWrapper">';
            optionsPlaceholder += '<div class="TextPlaceholder TextPlaceholder_length_medium"></div>';
            //@ts-ignore
            Object.keys(attributes[value].attribute_options).reduce((option, count) => {
                optionsPlaceholder += '<div class="TextPlaceholder TextPlaceholder-Option TextPlaceholder_length_short"></div>';
            }, []);
            optionsPlaceholder += '</div>';
        }, [])
        optionsPlaceholder += '</div>';
        return (
            <main
                block="Fallback"
                elem="ProductPagePlaceholder"
                aria-label="Product page"
            >
                    <article block="Fallback" elem="ProductActions">
                        <div block="ProductPage" elem="Top">
                            <TextPlaceholder content="" length={ TextPlaceHolderLength.LONG } />
                            <TextPlaceholder content="" length={ TextPlaceHolderLength.SHORT } />
                        </div>
                        <TextPlaceholder content="" length={ TextPlaceHolderLength.SHORT } />
                        <section
                            block="ProductActions"
                            elem="Section"
                            mods={ { type: 'alerts' } }
                        >
                            <TextPlaceholder content="" length={ TextPlaceHolderLength.MEDIUM } />
                        </section>

                        {
                            (optionsPlaceholder === '<div class="TextPlaceholder-Options"></div>') ? '':<Html content={optionsPlaceholder}/>
                        }


                        <div className="TextPlaceholder-AddToCartWrapper">
                            <TextPlaceholder content="" length={ TextPlaceHolderLength.LONG } />
                            <TextPlaceholder content="" length={ TextPlaceHolderLength.LONG } />
                        </div>
                        {/*<div className="TextPlaceholder-InfoIcons">*/}
                        {/*    <TextPlaceholder content="" length={ TextPlaceHolderLength.SHORT } />*/}
                        {/*    <TextPlaceholder content="" length={ TextPlaceHolderLength.SHORT } />*/}
                        {/*    <TextPlaceholder content="" length={ TextPlaceHolderLength.SHORT } />*/}
                        {/*    <TextPlaceholder content="" length={ TextPlaceHolderLength.SHORT } />*/}
                        {/*    <TextPlaceholder content="" length={ TextPlaceHolderLength.SHORT } />*/}
                        {/*</div>*/}
                        <div className="TextPlaceholder-ProductTabs">
                            <TextPlaceholder content="" length={ TextPlaceHolderLength.LONG } />
                            <TextPlaceholder content="" length={ TextPlaceHolderLength.LONG } />
                            <TextPlaceholder content="" length={ TextPlaceHolderLength.LONG } />
                            <TextPlaceholder content="" length={ TextPlaceHolderLength.LONG } />
                        </div>
                    </article>
            </main>
        );
    }

    renderProductActions() {
        const {
            getLink,
            dataSource,
            areDetailsLoaded,
            setActiveProduct,
            parameters,
            isMobile,
        } = this.props;

        return (
            <div id="ProductActions" block="ProductPage" elem="ProductActions">
                {!isMobile && this.renderProductDesktopMainData()}
                <Suspense fallback={this.renderProductActionsPlaceholder()}>
                {/*<Suspense fallback={null}>*/}
                    <ProductActions
                        getLink={getLink}
                        product={dataSource}
                        parameters={parameters}
                        areDetailsLoaded={areDetailsLoaded}
                        setActiveProduct={setActiveProduct}
                    />
                    {this.renderProductTabs()}
                </Suspense>
                {/*{this.renderProductTabs()}*/}
            </div>
        );
    }

    renderProductTabs(): ReactElement {
        const tabs = this.shouldTabsRender();

        if (!tabs) {
            return null;
        }

        return (
            <ProductTabs reviewAlone={false} tabs={tabs}/>
        );
    }

    renderAmReviews(): ReactElement {
        const tabs = this.shouldTabsRender();

        if (!tabs) {
            return null;
        }

        return (
            <ProductTabs reviewAlone={true} tabs={tabs}/>
        );
    }

    renderProductPageContent(): ReactElement {
        const {
            areDetailsLoaded,
            activeProduct,
            useEmptyGallerySwitcher,
            isVariant,
        } = this.props;

        return (
            <>
                <div block="ProductGalleryWrapper">
                     <ProductGallery
                         product={activeProduct}
                         areDetailsLoaded={areDetailsLoaded}
                         isWithEmptySwitcher={useEmptyGallerySwitcher}
                         showLoader={isVariant}
                     />
                    {/*<SwiperJSGallery product={activeProduct}/>*/}
                </div>
                {/*{this.renderNewGallery()}*/}
                {/*{this.initGallery()}*/}
                {this.renderProductActions()}

            </>
        )
        // return (
        //     <>
        //         <ProductGallery
        //             product={activeProduct}
        //             areDetailsLoaded={areDetailsLoaded}
        //             isWithEmptySwitcher={useEmptyGallerySwitcher}
        //             showLoader={isVariant}
        //         />
        //         {this.renderProductActions()}
        //     </>
        // );
    }

    renderProductAttributesTab(key: string): ReactElement {
        const {
            activeProduct,
            areDetailsLoaded,
        } = this.props;

        return (
            <Suspense fallback={<Loader/>} key={key}>
                <ProductAttributes
                    product={activeProduct}
                    areDetailsLoaded={areDetailsLoaded}
                    key={key}
                />
            </Suspense>
        );
    }

    render(): ReactElement {
        return (
            <NoMatchHandler>
                <main
                    block="ProductPage"
                    aria-label="Product page"
                    itemScope
                    itemType="http://schema.org/Product"
                >
                    <ContentWrapper
                        wrapperMix={{block: 'ProductPage', elem: 'Wrapper'}}
                        label={__('Main product details')}
                    >
                        {this.renderProductPageContent()}

                        {/* { this.initGallery() } */}
                    </ContentWrapper>
                    {this.renderReviewPopup()}
                    {this.renderAmReviews()}
                    {this.renderAdditionalSections()}
                </main>
            </NoMatchHandler>
        );
    }

}

export default ProductPageComponent;
